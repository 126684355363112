<template>
  <div>
    <a-page-header class="header_title" :title="$t('orders.title')" />
    <div class="box_content" style="max-height:700px">

      <a-form layout="inline" :form="form" @submit="handleSubmit">
      <a-form-item
        :style="{ display: 'inline-block', width: 'calc(150px - 12px)' }"
      >
      <a-date-picker :placeholder="$t('orders.search.item_1')" v-decorator="['order_date_start']"
        style="width: 100%" @change="(date, dateString)=>handleChange(date, dateString,'order_date_start')"/>
      </a-form-item>
        <a-form-item :style="{ display: 'inline-block', width: 'calc(20px - 12px)' }"
      > ~
      </a-form-item>
      <a-form-item :style="{ display: 'inline-block', width: 'calc(150px - 12px)' }"
      >
        <a-date-picker :placeholder="$t('orders.search.item_2')" v-decorator="['order_date_finish']" style="width: 100%" @change="(date, dateString)=>handleChange(date, dateString,'order_date_finish')"/>
      </a-form-item>
      <a-form-item :style="{ display: 'inline-block', width: 'calc(150px - 12px)' }">
        <a-input :placeholder="$t('orders.search.item_3')" v-decorator="['product_code']"/>
      </a-form-item>
      <a-form-item>
        <a-button class="ant-btn-danger" type="primary" html-type="submit">{{$t('orders.search.item_4')}}</a-button>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" @click="dowload_csv">{{$t('orders.search.item_5')}}</a-button>
      </a-form-item>
      <!-- <a-form-item label="Settlement amount"> CNY2000 </a-form-item> -->
    </a-form>
    <a-tabs default-active-key="0" @change="callback" :animated="false" size="large">
      <a-tab-pane key="0" :tab="$t('orders.tab_1')"></a-tab-pane>
      <a-tab-pane key="1" :tab="$t('orders.tab_3')" force-render></a-tab-pane>
      <!-- <a-tab-pane key="1" tab="Gyroor" force-render></a-tab-pane> -->
      <a-tab-pane key="2" tab="DataHunt">
        <a-button @click="addOrderBtn">{{$t('orders.tab_2_btn')}}</a-button>
        <div style="width:100%;height:16px;">&nbsp;</div>
      </a-tab-pane>
      <a-tab-pane key="5" tab="Microsel" force-render></a-tab-pane>
      <!-- <a-tab-pane key="3" tab="Return Helper"></a-tab-pane> -->
      <!-- <a-tab-pane key="4" tab="SZ Warehouse"></a-tab-pane> -->
    </a-tabs>
    <a-table
      :columns="columns"
      :data-source="data_source"
      :rowKey="(record, index) => index"
      :pagination="pagination"
      :loading="loading"
      @change="handleTableChange"
      :rowClassName="rowClassName"
      size="middle"
    >
    <!-- bordered -->
    <!-- :components="components" -->
      <span slot="image" slot-scope="text, data" class="td_black">
        <img
          v-bind:src="data.image"
          style="width: 50px; float: left; margin-right: 5px"
        />
      </span>
      <span slot="logistics_tracking_number" slot-scope="text, data">
        {{ text }}
        <span v-if="text == ''">
          <!-- <a @click="() => showModal(data.id,'','','')">Add</a> -->
          <a v-if="data.cancel ==0 " @click="() => showModal(data.id,data.dow_image,data.delivery_time,data.service_provider,data.logistics_tracking_number,data.deliver)"><a-icon type="upload" /></a>
        </span>
        <span v-else>
          <a v-if="data.cancel ==0 " @click="() => showModal(data.id,data.dow_image,data.delivery_time,data.service_provider,data.logistics_tracking_number,data.deliver)"><a-icon type="upload" /></a>
        </span>
      </span>
      <!-- <span slot="Status" slot-scope="text, data">
        {{ data.Status }}
      </span>
      -->
      <span slot="action" slot-scope="text, data">
          <div v-if="data.cancel == '0'">
          <!-- <a style="color:#ff4d4f;font-size: 18px;"><a-icon type="download" @click="dowload_img(data.dow_image)"/></a> -->
          <a @click="editBtn(data.id)">{{$t('orders.table.action.edit')}}</a>
          <!-- <a @click="deleteBtn(data.id,index)"><a-icon type="delete" /></a> -->
          <a-divider type="vertical" />
          <a @click="showModal_cancel(data.id)">{{$t('orders.table.action.cancel')}}</a>
          <a-divider type="vertical" />
          <a-popconfirm
            :title="$t('orders.alert.title')"
            :ok-text="$t('orders.alert.ok_text')"
            :cancel-text="$t('orders.alert.cancel_text')"
            @confirm="deleteBtn(data.id,index)"
            @cancel="cancel"
          ><a href="javascript:void(0)">{{$t('orders.table.action.delete')}}</a></a-popconfirm>
        </div>
        <div v-if="data.cancel == '1'">{{$t('orders.table.action.canceled')}}</div>
      </span>

    </a-table>

    <a-modal v-model="visible_modal" :title="$t('orders.model.title')" @ok="handleOk">
      <p>
        <!-- <a-input @change="saveDeliveryTime" :value="delivery_time" placeholder="Delivery Time"/> -->
        <a-date-picker style="width: 100%" :value="delivery_time" @change="saveDeliveryTime" :placeholder="$t('orders.model.item_1')"/>
      </p>
      <p>
        <a-input @change="serviceProvider" :value="service_provider" :placeholder="$t('orders.model.item_2')"/>
      </p>
      <p>
        <a-input @change="saveLogisticsTrackingNumber" :value="logistics_tracking_number" :placeholder="$t('orders.model.item_3')"/>
      </p>
      <p>
        <a-input @change="saveLogisticsPriceDifference" :value="logistics_price_difference" :placeholder="$t('orders.model.item_4')"/>
      </p>
      <p>
        {{$t('orders.model.item_9')}}
        <a-upload
          name="file"
          :multiple="true"
          :headers="headers"
          :default-file-list="defaultFileList"
          action="https://labs.datahunt.app/index.php/upload"
          @change="handleChange"
        >
          <a-button v-if="isShow"> <a-icon type="upload" /> {{$t('orders.model.item_5')}} </a-button>
        </a-upload>
      </p>
      <p>
        {{$t('orders.model.item_10')}}
          <a-select style="width: 150px" :value="delivery" @change="saveDelivery">
              <!-- <a-select-option value="">{{$t('form.select_info')}}</a-select-option> -->
              <a-select-option value="2">{{$t('orders.model.item_8')}}</a-select-option>
              <a-select-option value="1">{{$t('orders.model.item_7')}}</a-select-option>
          </a-select>
      </p>
    </a-modal>
    
    <a-modal v-model="visible_modal_cancel" :title="$t('orders.model.cancel')" @ok="handleOkCancel">
      
      <p>
        <a-input @change="cancelReason" :value="cancel_reason" :placeholder="$t('orders.model.item_6')"/>
      </p>

    </a-modal>

  </div>
  </div>
</template>

<script>

// import Vue from 'vue'
// import VueDraggableResizable from 'vue-draggable-resizable'

// Vue.component('vue-draggable-resizable', VueDraggableResizable)

import {
  getList,
  saveLogisticsTrackingNumber,
  dowloadCsv,
  del,
  cancelReason,
} from "@/network/order3";
export default {
  name: "NodeprojectOrders",
  components: {},
  directives: {},
  data() {
    // this.components = {
    //   header: {
    //     cell: (h, props, children) => {
    //       const { key, ...restProps } = props
    //       console.log('ResizeableTitle', key)
    //       const col = this.columns.find(col => {
    //         const k = col.dataIndex || col.key
    //         return k === key
    //       })

    //       if (!col || !col.width) {
    //         return h('th', { ...restProps }, [...children])
    //       }

    //       const dragProps = {
    //         key: col.dataIndex || col.key,
    //         class: 'table-draggable-handle',
    //         attrs: {
    //           w: 10,
    //           x: col.width,
    //           z: 1,
    //           axis: 'x',
    //           draggable: true,
    //           resizable: false
    //         },
    //         on: {
    //           dragging: (x, y) => {
    //             col.width = Math.max(x, 1)
    //           }
    //         }
    //       }
    //       const drag = h('vue-draggable-resizable', { ...dragProps })
    //       return h('th', { ...restProps, class: 'resize-table-th' }, [...children, drag])
    //     }
    //   }
    // }
    return {
      data_source:[],
      visible: false,
      pagination: { defaultPageSize: 7, current: 1, total: 0 },
      // columns,
      loading: false,
      visible_modal: false,
      loading2:false,
      form: this.$form.createForm(this, { name: 'coordinated' }),
      order_date_start:'',
      order_date_finish:'',
      params:{},
      order_id : 0,
      delivery_time:'',
      service_provider:'',
      logistics_tracking_number:'',
      delivery:'',
      page:1,
      Warehouse:0,
      defaultFileList:[],
      headers: {
        'X-Requested-With': null,
      },
      path:'',
      isShow:true,
      visible_modal_cancel:false,
      cancel_reason:'',
    };
  },
  created() {
    this.getList(1);
  },
  mounted(){

  },
  computed:{
    columns(){
      return [
      {
        title: this.$t('orders.table.head_titel_1'),//下单日期
        dataIndex: "order_date",
        key: "order_date",
        // width: 105
      },
      {
        title: this.$t('orders.table.head_titel_2'),//图片
        dataIndex: "image",
        key: "image",
        scopedSlots: { customRender: 'image' },
        // width: 80
      },
      {
        title: this.$t('orders.table.head_titel_3'),//产品代码
        dataIndex: "product_code",
        key: "product_code",
        // width: 120
      },
      {
        title: this.$t('orders.table.head_titel_4'),
        dataIndex: "cost",
        key: "cost",
        // width: 80
      },
      {
        title: this.$t('orders.table.head_titel_5'),//订单号
        dataIndex: "order_number",
        key: "order_number",
        // width: 130
      },
      {
        title: this.$t('orders.table.head_titel_6'),//产品型号
        dataIndex: "product_model",
        key: "product_model",
        // width: 80
      },
      {
        title: this.$t('orders.table.head_titel_7'),//国家
        dataIndex: "country",
        key: "country",
        // width: 50
      },
      {
        title: this.$t('orders.table.head_titel_8'),//收件人
        dataIndex: "addressee",
        key: "addressee",
        // width: 80
      },
      {
        title: this.$t('orders.table.head_titel_9'),//收件人地址
        dataIndex: "recipient_address",
        key: "recipient_address",
        // width: 100
      },
      {
        title: this.$t('orders.table.head_titel_10'),//电话号码
        dataIndex: "telephone_number",
        key: "telephone_number",
        // width: 80
      },
      {
        title: this.$t('orders.table.head_titel_11'),//邮编
        dataIndex: "postal_code",
        key: "postal_code",
        // width: 50
      },
      {
        title: this.$t('orders.table.head_titel_12'),//数量
        dataIndex: "quantity",
        key: "quantity",
        // width: 50
      },
      {
        title: this.$t('orders.table.head_titel_13'),//发货日期
        dataIndex: "delivery_time",
        key: "delivery_time",
        // width: 80
      },
      {
        title: this.$t('orders.table.head_titel_14'),//物流渠道/服务商
        dataIndex: "service_provider",
        key: "service_provider",
        // width: 80
      },
      {
        title: this.$t('orders.table.head_titel_15'),//物流差价
        dataIndex: "logistics_price_difference",
        key: "logistics_price_difference",
        // scopedSlots: { customRender: "logistics_price_difference" },
        // width: 80
      },
      {
        title: this.$t('orders.table.head_titel_16'),//物流跟踪号
        dataIndex: "logistics_tracking_number",
        key: "logistics_tracking_number",
        scopedSlots: { customRender: "logistics_tracking_number" },
        // width: 80
      },
      {
        title: this.$t('orders.table.head_titel_17'),
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' },
        width: 160
      },
    ];
    }
  },
  methods: {
    rowClassName(record, index){
      console.log(record);
      if(record.cancel == '1'){
        return 'changeBg';
      }else{
        return '';
      }
    },
    handleChange(info) {
      console.log(info);
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'removed') {
        this.isShow = true;
        this.path = '';
      }else if (info.file.status === 'done') {
        if(info.file.response.code == 0){
          this.isShow = false;
          this.path = info.file.response.path;
        }
        this.$message.success('file uploaded successfully');
      } else if (info.file.status === 'error') {
        this.$message.error('file upload failed');
      }
    },
    cancel(){
      
    },
    deleteBtn(e, index) {
      del(e).then((res) => {
        if (res.code == 0) {
          this.data_source.splice(index, 1);
          this.$message.success("delete success!");
        }
      });
    },
    editBtn(e) {
      this.$router.push({
        path: `/orders3/edit`,
        query: { order_id: e },
      });
    },
    addOrderBtn() {
      this.$router.push("/orders3/add");
    },
    callback(key) {
      console.log(key);
      this.Warehouse = key;
      this.getList(1);
    },
    dowload_csv(e){
      e.preventDefault();
      this.loading = false;
      this.form.validateFields((err, values) => {
        if (!err) {
          this.params = {
            product_code:values.product_code,
            order_date_start:this.order_date_start,
            order_date_finish:this.order_date_finish,
          };
          dowloadCsv(this.params).then((res) => {
            this.loading = false;
            if (res.code == 0) {
              // window.open('http://makeradmin.net'+res.file);
              window.open('https://labs.datahunt.app/'+res.file);
            }
          });
        }else{
          this.loading = false;
        }
      });
    },
    dowload_img(url){
        if(url){
          // window.open('http://makeradmin.net'+url);
          window.open('https://labs.datahunt.app/'+url);
        }else{
          this.$message.error('no data')
        }
    },
    showModal_cancel(id){
      this.order_id = id;
      this.visible_modal_cancel = true;

    },
    showModal(id,dow_image,delivery_time,service_provider,logistics_tracking_number,delivery) {
      console.log(dow_image);
      this.order_id = id;
      this.delivery_time = delivery_time;
      this.service_provider = service_provider;
      this.logistics_tracking_number = logistics_tracking_number;
      this.delivery = delivery;
      if(dow_image){
        this.defaultFileList =[{
          uid: '1',
          name: dow_image,
          status: 'done',
          response: 'success',
          url: 'https://labs.datahunt.app/'+dow_image,
        }];
        this.path = dow_image;
        this.isShow = false;
      }else{
        this.isShow = true;
      }
      this.visible_modal = true;
    },
    saveDeliveryTime(date, dateString)
    {
      this.delivery_time = dateString;
    },
    saveDelivery(e)
    {
      // console.log(e);
      this.delivery = e;
    },
    serviceProvider(e)
    {
      this.service_provider = e.target.value;
    },
    saveLogisticsTrackingNumber(e)
    {
      this.logistics_tracking_number = e.target.value;
    },
    saveLogisticsPriceDifference(e)
    {
      this.logistics_price_difference = e.target.value;
    },
    cancelReason(e)
    {
      this.cancel_reason = e.target.value;
    },
    handleOkCancel(){
      console.log(this.cancel_reason);
      cancelReason({id:this.order_id,reason:this.cancel_reason}).then((res) => {
        this.visible_modal_cancel = false;
        if (res.code == 0) {
          this.order_id = '';
          this.getList(this.page);
        }
      });
    },
    handleOk(){
      // if(this.order_id,this.delivery_time,this.service_provider,this.logistics_tracking_number){
        saveLogisticsTrackingNumber({id:this.order_id,dow_image:this.path,delivery_time:this.delivery_time,delivery:this.delivery,service_provider:this.service_provider,logistics_tracking_number:this.logistics_tracking_number,logistics_price_difference:this.logistics_price_difference}).then((res) => {
          this.visible_modal = false;
          if (res.code == 0) {
            this.order_id         = '';
            this.delivery_time    = '';
            this.delivery    = '';
            this.service_provider = '';
            this.logistics_tracking_number = '';
            this.path             = '';
            this.getList(this.page);
          }
        });
      // }else{

      // }
    },
    handleChange(date, dateString,k){
      if(k == 'order_date_start'){
        this.order_date_start = dateString;
      }
      if(k == 'order_date_finish'){
        this.order_date_finish = dateString;
      }
    },
    handleSubmit(e){
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values);
           this.params = {
            product_code:values.product_code,
            order_date_start:this.order_date_start,
            order_date_finish:this.order_date_finish,
          };
          this.getList(1);
        }
      });
     
    },
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination);
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.page = pagination.current;
      this.getList(pagination.current);
      // this.fetch({
      //   results: pagination.pageSize,
      //   page: pagination.current,
      //   sortField: sorter.field,
      //   sortOrder: sorter.order,
      //   ...filters,
      // });
    },
    getList(page) {
      this.loading = true;
      this.params.Warehouse = this.Warehouse;
      getList(page,this.params).then((res) => {
        this.loading = false;
        if (res.code == 0) {
            const pagination = { ...this.pagination };
            pagination.total = res.total;
            this.pagination = pagination;
            this.data_source = res.data;
        }
      });
    },
  },
};

</script>

<style>
.changeBg{
  background-color:#ff105426 !important;
}
.ant-table-tbody>tr .ant-table-row td{
  /* background-color: unset; */
}
.resize-table-th {
  position: relative;
}
.table-draggable-handle {
  /* width: 10px !important; */
  height: 100% !important;
  left: auto !important;
  right: -5px;
  cursor: col-resize;
  touch-action: none;
  border: none;
  transform:none;position:absolute;
}
</style>